import { lazy } from "react";

import Loadable from "../components/Loadable";

import OnboardingLayout from "../components/layout/other/OnboardingLayout";

const OnboardPage = Loadable(
  lazy(() => import("../scenes/onboarding/MemberOnboardingPage"))
);

const OnboardRoutes = {
  path: "/",
  element: <OnboardingLayout />,
  children: [
    {
      path: "/onboarding",
      element: <OnboardPage />,
    },
  ],
};

export default OnboardRoutes;

import * as React from "react";
import { Layout } from "../../components/auth/layout";
import { GuestGuard } from "../../components/auth/guest-guard";
import { ResetPasswordForm } from "../../components/auth/reset-password-form";

export default function AuthResetPassword() {
  return (
    <Layout>
      <GuestGuard>
        <ResetPasswordForm />
      </GuestGuard>
    </Layout>
  );
}

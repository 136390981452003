export const AppConstants = {
  STORAGE_KEYS: {
    USER_SESSION: "user_session",
    VERIFICATION: 'verification',
  },
  HTTP: {
    LOGIN: "/auth/login/",
    REGISTER: "/auth/register/",
    VERIFY_EMAIL: "/auth/verify-email/",
    GET_USER: "/auth/user/",
    TOKEN_REFRESH: "/auth/token/refresh/",
    AI_CONVERSATION: '/api/assistant/'
  },
  URL: {
    PROD: 'https://dashboard.livemoreai.com',
    DEV: 'http://127.0.0.1:8000'
  }
};


export const TABS = { PERSONAL_DETAILS: "details", CHAT_HISTORY: "history" };
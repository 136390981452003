import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { paths } from "../../lib/paths";

export function GuestGuard({ children }) {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading } = useSelector((state) => state.auth);

  React.useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate(paths.dashboard.main);
    }
  }, [isAuthenticated, isLoading, navigate]);

  if (isLoading) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

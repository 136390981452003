/* eslint-disable no-unneeded-ternary */
/* eslint-disable default-param-last */
import * as healthDataType from "../constants/healthdata.constants.js";

const initialState = {
  patient: null,
  contact: null,
  emergency: null,
  social: null,
  medication: null,
  condition: null,
  surgeries: null,
  lifestyle: null,
  members: [],
};

export default function healthDataReducer(state = initialState, action) {
  switch (action.type) {
    case healthDataType.UPDATE_MEMBERS_LIST:
      return {
        ...state,
        members: action.payload,
      };
    case healthDataType.UPDATE_BASIC_INFORMATION:
      return {
        ...state,
        patient: action.payload,
      };
    case healthDataType.UPDATE_CONTACT_INFORMATION:
      return {
        ...state,
        contact: action.payload,
      };
    case healthDataType.UPDATE_EMERGENCY_INFORMATION:
      return {
        ...state,
        emergency: action.payload,
      };
    case healthDataType.UPDATE_SOCIAL_INFORMATION:
      return {
        ...state,
        social: action.payload,
      };
    case healthDataType.UPDATE_MEDICATION_INFORMATION:
      return {
        ...state,
        medication: action.payload,
      };
    case healthDataType.UPDATE_CONDITION_HISTORY_INFORMATION:
      return {
        ...state,
        condition: action.payload,
      };
    case healthDataType.UPDATE_SURGICAL_HISTORY_INFORMATION:
      return {
        ...state,
        surgeries: action.payload,
      };
    case healthDataType.UPDATE_LIFESTYLE_INFORMATION:
      return {
        ...state,
        lifestyle: action.payload,
      };
    default:
      return state;
  }
}

import { lazy } from "react";

import DashboardLayout from "../components/layout/main/DashboardLayout";
import Loadable from "../components/Loadable";

const DashboardDefault = Loadable(
  lazy(() => import("../scenes/dashboard/index"))
);

const MainRoutes = {
  path: "/",
  element: <DashboardLayout />,
  children: [
    {
      path: "/",
      element: <DashboardDefault />,
    },
  ],
};

export default MainRoutes;

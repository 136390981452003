import * as api from "../../api/healthdata.api";
import * as constant from "../constants/healthdata.constants";

export const loadApplicationData = () => async (dispatch, getState) => {
  dispatch({ type: constant.TOGGLE_LOADING });

  try {
    const requests = await Promise.all([
      api.patientAPI.getAll(),
      api.contactInformationAPI.getAll(),
      api.emergencyInformationAPI.getAll(),
      api.socialOccupationalHistoryAPI.getAll(),
      api.medicationsAndAllergiesAPI.getAll(),
      api.chronicConditionsAndMedicalHistoryAPI.getAll(),
      api.surgicalAndHospitalizationHistoryAPI.getAll(),
      api.lifestyleAndPreventiveCareAPI.getAll(),
      api.doctorAPI.getAll(),
    ]);

    if (requests[0].ok) {
      dispatch({
        type: constant.UPDATE_BASIC_INFORMATION,
        payload: requests[0].data,
      });
    }

    if (requests[1].ok) {
      dispatch({
        type: constant.UPDATE_CONTACT_INFORMATION,
        payload: requests[1].data,
      });
    }

    if (requests[2].ok) {
      dispatch({
        type: constant.UPDATE_EMERGENCY_INFORMATION,
        payload: requests[2].data,
      });
    }

    if (requests[3].ok) {
      dispatch({
        type: constant.UPDATE_SOCIAL_INFORMATION,
        payload: requests[3].data,
      });
    }

    if (requests[4].ok) {
      dispatch({
        type: constant.UPDATE_MEDICATION_INFORMATION,
        payload: requests[4].data,
      });
    }

    if (requests[5].ok) {
      dispatch({
        type: constant.UPDATE_CONDITION_HISTORY_INFORMATION,
        payload: requests[5].data,
      });
    }

    if (requests[6].ok) {
      dispatch({
        type: constant.UPDATE_SURGICAL_HISTORY_INFORMATION,
        payload: requests[6].data,
      });
    }

    if (requests[7].ok) {
      dispatch({
        type: constant.UPDATE_LIFESTYLE_INFORMATION,
        payload: requests[7].data,
      });
    }
    if (requests[8].ok) {
      dispatch({
        type: constant.UPDATE_MEMBERS_LIST,
        payload: requests[8].data,
      });
    }
  } catch (error) {
    console.error("Failed to load application data", error);
  } finally {
    dispatch({ type: constant.TOGGLE_LOADING });
  }
};

import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import { AuthGuard } from "../../auth/auth-guard";

export default function OnboardingLayout() {
  return (
    <AuthGuard>
      <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
        <Box component="main">
          <Outlet />
        </Box>
      </Box>
    </AuthGuard>
  );
}

import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { DynamicLogo } from "../logo";
import { Link } from "react-router-dom";
import { paths } from "../../lib/paths";

export function Layout({ children }) {
  return (
    <Box
      sx={{
        display: { xs: "flex", lg: "grid" },
        flexDirection: "column-reverse",
        gridTemplateColumns: "1fr 1fr",
        width: "100dvw",
        height: "100dvh",
      }}
    >
      <Box
        sx={{
          alignItems: "flex-start",
          backgroundColor: "#37c5f9",
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://images.unsplash.com/photo-1711024106249-414f72740f16?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`, // Initial image
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "var(--mui-palette-common-white)",
          display: { xs: "none", lg: "flex" },
          flexDirection: "column",
          justifyContent: "space-between",
          margin: 1,
          padding: 3,
          borderRadius: 2,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Adding a subtle shadow
          animation: "slideshow 15s infinite ease-in-out", // Animation for the background
        }}
      >
        <Box>
          <Box
            component={Link}
            href={paths.dashboard.main}
            sx={{ display: "inline-block", fontSize: 0 }}
          >
            <DynamicLogo
              colorDark="light"
              colorLight="light"
              height={32}
              width={122}
            />
          </Box>
        </Box>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Typography
              color="inherit"
              sx={{
                fontSize: "35px",
                fontWeight: "600",
                animation: "fadeInText 10s ease-in-out infinite",
                fontFamily: "serif",
              }}
              variant="h1"
            >
              Precision care <br />
              <span style={{ color: "#37c5f9" }}>for a healthier you</span>
              <br />
              and disease-free future
            </Typography>
          </Stack>
        </Stack>
      </Box>

      {/* Add the CSS animations below */}
      <style jsx>{`
        @keyframes slideshow {
          0% {
            background-image: linear-gradient(
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
              ),
              url("https://images.unsplash.com/photo-1694113280467-b742509058d1?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
          }
          33% {
            background-image: linear-gradient(
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
              ),
              url("https://images.unsplash.com/photo-1488228469209-c141f8bcd723?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
          }
          66% {
            background-image: linear-gradient(
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
              ),
              url("https://images.unsplash.com/photo-1543362906-acfc16c67564?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
          }
          100% {
            background-image: linear-gradient(
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
              ),
              url("https://images.unsplash.com/photo-1624272864537-8ecc72b67958?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
          }
        }

        @keyframes fadeInText {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          50% {
            opacity: 1;
            transform: translateY(0);
          }
          100% {
            opacity: 0;
            transform: translateY(-20px);
          }
        }
      `}</style>

      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

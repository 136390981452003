import { lazy } from "react";

import AuthLayout from "../components/layout/auth/AuthLayout";
import Loadable from "../components/Loadable";
import AuthVerifyEmail from "../scenes/auth/VerifyEmail";
import AuthResetPassword from "../scenes/auth/ResetPassword";

const AuthLogin = Loadable(lazy(() => import("../scenes/auth/AuthLogin")));
const AuthRegister = Loadable(
  lazy(() => import("../scenes/auth/AuthRegister"))
);

const AuthRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: "/login",
      element: <AuthLogin />,
    },
    {
      path: "/register",
      element: <AuthRegister />,
    },
    {
      path: "/reset",
      element: <AuthResetPassword />,
    },
    {
      path: "/verify-email",
      element: <AuthVerifyEmail />,
    },
  ],
};

export default AuthRoutes;

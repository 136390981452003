import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk"; // fix import
import rootReducer from "./reducers";

const initialState = {};
const middleWare = [thunk];

let store;

if (process.env.NODE_ENV === "development") {
  const composeWithDevTools =
    (typeof window !== "undefined" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    require("redux-devtools-extension").composeWithDevTools;

  console.log(composeWithDevTools);

  try {
    store = createStore(
      rootReducer,
      initialState,
      composeWithDevTools
        ? composeWithDevTools(applyMiddleware(...middleWare))
        : applyMiddleware(...middleWare)
    );
  } catch (error) {
    store = createStore(
      rootReducer,
      initialState,
      applyMiddleware(...middleWare)
    );
  }
} else {
  store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middleWare)
  );
}

export { store };

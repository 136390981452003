import { Stack, Box, Typography, Avatar } from "@mui/material";
import React from "react";

import { FiBell, FiChevronDown } from "react-icons/fi";
import { IoHelpBuoyOutline } from "react-icons/io5";
import { usePopover } from "../../hooks/use-popover";
import { UserPopover } from "./Popover";
import { useSelector } from "react-redux";
import { DynamicLogo } from "../logo";

export const TopHeader = () => {
  const userPopover = usePopover();

  const { user } = useSelector((state) => state.auth);

  return (
    <React.Fragment>
      <Stack
        px={4}
        py={2}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
        >
          <Box href={"#"} sx={{ display: "inline-block", fontSize: 0 }}>
            <DynamicLogo
              colorDark="light"
              colorLight="dark"
              height={32}
              width={122}
            />
          </Box>
          <Typography
            sx={{ display: { xs: "none", sm: "block" } }}
            variant="subtitle1"
          >
            Precision Medicine
          </Typography>
        </Stack>
        {/* Search input  */}
        {/* <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: { xs: "none", lg: "flex" },
            alignItems: "center",
            width: 500,
            bgcolor: "#F9FAFB",
          }}
        >
          <IconButton sx={{ p: "10px" }} aria-label="menu">
            <FiSearch />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search here..."
            inputProps={{ "aria-label": "search livemoreai" }}
          />
        </Paper> */}
        {/* user data  */}
        <Stack direction={"row"} spacing={2}>
          <Stack
            display={{ xs: "none", md: "flex" }}
            direction={"row"}
            spacing={1}
          >
            <Stack
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
            >
              <Avatar
                sx={{ width: "22px", height: "22px", cursor: "pointer" }}
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/Flag_of_the_United_States.png/1200px-Flag_of_the_United_States.png"
                }
              />

              <Typography variant="subtitle2">Eng (US) </Typography>

              <FiChevronDown />
            </Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#E7ECFC",
                width: "40px",
                height: "40px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              <IoHelpBuoyOutline color={"#37C5F9"} fontSize={"20px"} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#E7ECFC",
                width: "40px",
                height: "40px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              <FiBell color={"#37C5F9"} fontSize={"20px"} />
            </Box>
          </Stack>
          <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
            onClick={userPopover.handleOpen}
            ref={userPopover.anchorRef}
          >
            <Avatar sx={{ borderRadius: "10px", cursor: "pointer" }} src={""} />
            <Stack>
              <Typography variant="subtitle2">{user?.first_name}</Typography>
              <Typography variant="caption">
                {user?.role &&
                  `${user?.role[0]?.toUpperCase()}${user?.role.slice(1)}`}
              </Typography>
            </Stack>
            <FiChevronDown style={{ marginBottom: "6px" }} />
            <UserPopover
              anchorEl={userPopover.anchorRef.current}
              onClose={userPopover.handleClose}
              open={userPopover.open}
            />
          </Stack>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

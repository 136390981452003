import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import { GuestGuard } from "../../auth/guest-guard";

export default function AuthLayout() {
  return (
    <GuestGuard>
      <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
        <Box component="main">
          <Outlet />
        </Box>
      </Box>
    </GuestGuard>
  );
}

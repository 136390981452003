import { Box, Stack } from "@mui/material";
import React from "react";

export default function FullScreenLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "white",
        width: "100dvw",
        height: "100dvh",
      }}
    >
      <Stack sx={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <img
          src="https://i.postimg.cc/YC3yYZk8/livmore-loading.gif"
          alt={"loader"}
          width={100}
          height={100}
          style={{border: '2px solid #37C5F9', borderRadius: '100%'}}
        />
      </Stack>
    </Box>
  );
}

export const california = {
  50: "#fffaea",
  100: "#fff3c6",
  200: "#ffe587",
  300: "#ffd049",
  400: "#ffbb1f",
  500: "#fb9c0c",
  600: "#de7101",
  700: "#b84d05",
  800: "#953b0b",
  900: "#7b310c",
  950: "#471701",
};

export const kepple = {
  50: "#f0fdfa",
  100: "#ccfbef",
  200: "#9af5e1",
  300: "#5fe9ce",
  400: "#2ed3b8",
  500: "#15b79f",
  600: "#0e9382",
  700: "#107569",
  800: "#115e56",
  900: "#134e48",
  950: "#042f2c",
};

export const neonBlue = {
  50: "#D7FEFE",
  100: "#D7FEFE",
  200: "#AFF9FE",
  300: "#86EDFD",
  400: "#68DEFB",
  500: "#37C5F9",
  600: "#289BD6",
  700: "#1B75B3",
  800: "#115490",
  900: "#0A3C77",
  950: "#0A3C77",
};

export const nevada = {
  50: "#fbfcfe",
  100: "#f0f4f8",
  200: "#dde7ee",
  300: "#cdd7e1",
  400: "#9fa6ad",
  500: "#636b74",
  600: "#555e68",
  700: "#32383e",
  800: "#202427",
  900: "#121517",
  950: "#090a0b",
};

export const redOrange = {
  50: "#fef3f2",
  100: "#fee4e2",
  200: "#ffcdc9",
  300: "#fdaaa4",
  400: "#f97970",
  500: "#f04438",
  600: "#de3024",
  700: "#bb241a",
  800: "#9a221a",
  900: "#80231c",
  950: "#460d09",
};

export const shakespeare = {
  50: "#ecfdff",
  100: "#cff7fe",
  200: "#a4eefd",
  300: "#66e0fa",
  400: "#10bee8",
  500: "#04aad6",
  600: "#0787b3",
  700: "#0d6d91",
  800: "#145876",
  900: "#154964",
  950: "#082f44",
};

export const stormGrey = {
  50: "#f9fafb",
  100: "#f1f1f4",
  200: "#dcdfe4",
  300: "#b3b9c6",
  400: "#8a94a6",
  500: "#667085",
  600: "#565e73",
  700: "#434a60",
  800: "#313749",
  900: "#212636",
  950: "#121621",
};

import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import { AuthGuard } from "../../auth/auth-guard";
import { TopHeader } from "../TopHeader";

export default function DashboardLayout() {
  return (
    <AuthGuard>
      <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
        <Box component="main" sx={{ width: "calc(100%)", flexGrow: 1, height: '100%' }}>
          <TopHeader />
          <Outlet />
        </Box>
      </Box>
    </AuthGuard>
  );
}

import * as React from "react";
import { Layout } from "../../components/auth/layout";
import { GuestGuard } from "../../components/auth/guest-guard";
import { VerifyEmailForm } from "../../components/auth/verify-email-form";

export default function AuthVerifyEmail() {
  return (
    <Layout>
      <GuestGuard>
        <VerifyEmailForm />
      </GuestGuard>
    </Layout>
  );
}

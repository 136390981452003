import { AppConstants } from "../lib/constants";
import { http } from "./config";

export const register = async (payload) =>
  http.post(AppConstants.HTTP.REGISTER, payload);

export const login = async (payload) =>
  http.post(AppConstants.HTTP.LOGIN, payload);

export const verifyEmail = async (payload) =>
  http.post(AppConstants.HTTP.VERIFY_EMAIL, payload);

export const getUser = (token) =>
  http.get(
    AppConstants.HTTP.GET_USER,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

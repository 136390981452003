import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { paths } from "../../lib/paths";
import FullScreenLoader from "../loading/FullScreenLoader";
import { getUser } from "../../api/auth.api";
import {
  AUTH_ERROR,
  USER_LOADED,
  USER_LOADING,
} from "../../store/constants/auth";
import { AppConstants } from "../../lib/constants";
import { loadApplicationData } from "../../store/actions/healthdata";
import { loadDoctorApplicationData } from "../../store/actions/doctor";

export function AuthGuard({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, isAuthenticated } = useSelector((state) => state.auth);

  const checkSession = React.useCallback(async () => {
    dispatch({ type: USER_LOADING });

    const userSession = localStorage.getItem(
      AppConstants.STORAGE_KEYS.USER_SESSION
    );

    if (!userSession) {
      dispatch({ type: AUTH_ERROR });
      navigate(paths.auth.signIn);
      return;
    }

    const parsedSession = JSON.parse(userSession);
    const response = await getUser(parsedSession.access);

    if (response.ok) {
      dispatch({
        type: USER_LOADED,
        payload: { user: response.data, ...parsedSession },
      });

      if (response.data?.role === "member") {
        dispatch(loadApplicationData());
        if(!response?.data?.onboarding) {
          navigate(paths.onboarding.main);
        }
      }

      if (response.data?.role === "doctor") {
        dispatch(loadDoctorApplicationData());
      }

      return;
    }

    dispatch({ type: AUTH_ERROR });
    navigate(paths.auth.signIn);
  }, [dispatch, navigate]);

  React.useEffect(() => {
    checkSession();
  }, [checkSession]);

  // React.useEffect(() => {
  //   if (!isLoading && !isAuthenticated) {
  //     navigate(paths.auth.signIn);
  //   }
  // }, [isLoading, isAuthenticated, navigate]);

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return <>{children}</>;
}

import * as api from "../../api/healthdata.api";
import * as constant from "../constants/healthdata.constants";

export const loadDoctorApplicationData = () => async (dispatch, getState) => {
  dispatch({ type: constant.TOGGLE_LOADING });

  try {
    const [getMembers] = await Promise.all([api.doctorAPI.getAll()]);

    if (getMembers.ok) {
      dispatch({
        type: constant.UPDATE_MEMBERS_LIST,
        payload: getMembers.data,
      });
    }
  } catch (error) {
    console.error("Failed to load application data", error);
  } finally {
    dispatch({ type: constant.TOGGLE_LOADING });
  }
};

import { createBrowserRouter } from "react-router-dom";

import MainRoutes from "./MainRoutes";
import AuthRoutes from "./AuthRoutes";
import OnboardRoutes from "./OnboardRoute";

const router = createBrowserRouter([MainRoutes, OnboardRoutes, AuthRoutes], {
  basename: "/",
});

export default router;
